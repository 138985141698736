import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"


const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 3" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 3</h1>
      <p>Hei Mamma!!!</p>
      <p>I dag er jeg så gira altså! Tenk, jeg har blitt 16 uker allerede! Og vet du hva? Det har vært de desidert beste 16 ukene av mitt liv!</p>
      <p>Ja, jeg vet at jeg gulper en del, og med all spenningen og bevegelsene som jeg sakte men sikkert utsetter meg mer for, så blir det nok ikke mindre gulping med det første. Så jeg tenkte at dagens ekstragave passet godt.</p>
      <p>Takk for at du hjelper meg med å utvikle meg, hvert eneste våkne minutt!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke